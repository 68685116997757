var moment = require("moment")
const timeZone = moment.tz.guess();
const time = new Date();
const timeZoneOffset = time.getTimezoneOffset();

export const headListFootball = [
    { code: "pickOutcome", title: "Outcome", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "favorite", title: "Favorite", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "favoritePicked", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "vs", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "underdogPicked", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "underdog", title: "Underdog", mobileHide: false, isNumeric: false, alignment: 'left' },
    { code: "open", title: "Open", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "monday", title: "Mon", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "tuesday", title: "Tues", mobileHide: false, isNumeric: false, alignment: 'right'},
    { code: "wednesday", title: "Wed", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "spread", title: "Final", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "startAt", title: `Status (${moment.tz.zone(timeZone).abbr(timeZoneOffset)})`, mobileHide: false, isNumeric: false,  type: "dateString", format: "L h:mm A", alignment: 'left' },
    { code: "point", title: "Points", mobileHide: false, isNumeric: false, alignment: 'left' },
];

export const adminHeadListFootball = [
    { code: "pickOutcome", title: "Outcome", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "favorite", title: "Favorite", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "favoritePicked", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "vs", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "underdogPicked", title: "", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "underdog", title: "Underdog", mobileHide: false, isNumeric: false, alignment: 'left' },
    { code: "open", title: "Open", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "monday", title: "Mon", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "tuesday", title: "Tues", mobileHide: false, isNumeric: false, alignment: 'right'},
    { code: "wednesday", title: "Wed", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "spread", title: "Final", mobileHide: false, isNumeric: false, alignment: 'right' },
    { code: "startAt", title: `Status (${moment.tz.zone(timeZone).abbr(timeZoneOffset)})`, mobileHide: false, isNumeric: false,  type: "dateString", format: "L h:mm A", alignment: 'left' },
    { code: "point", title: "Points", mobileHide: false, isNumeric: false, alignment: 'center' },
    { code: "hide", title: "Hide", mobileHide: false, isNumeric: false, alignment: 'left' },
    { code: "edit", title: "Edit", mobileHide: false, isNumeric: false, alignment: 'left' },
    { code: "tiebreaker", title: "Tiebreaker", mobileHide: false, isNumeric: false, alignment: 'left' },
];