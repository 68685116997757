import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemTable } from "components/common/Table/PickemTable";
import { headList } from "./model";
import { Link } from "react-router-dom";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { TextInput } from "components/common/Form/TextInput/TextInput";
import Checkbox from "components/common/Form/Checkbox/Checkbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemStandingsPage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const PickemStandingsPage = ({
    history,
    location,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [isLoading, setIsLoading] = useState(true)
    const [standingsDisplayData, setStandingsDisplayData] = useState(null)
    const [standingsData, setStandingsData] = useState({})
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [currentWeek, setCurrentWeek] = useState(null)
    const [selectedWeek, setSelectedWeek] = useState(null)
    const [isCommissioner, setIsCommissioner] = useState(false)

    useEffect(() => {
        updateMeta({
            title: "Standings",
        });
    }, []);

    useEffect(() => {
        getStandings(false)
    }, [])

    useEffect(() => {
        analyzeStandingsData()
    }, [standingsData])

    useEffect(() => {
        if (standingsDisplayData && standingsDisplayData.length > 0) {
            setIsLoading(false)
        }
    }, [standingsDisplayData])

    const getStandings = (runAllWeeks, weekNumber) => {
        functions.httpsCallable('pickemGetStandingsPage')({
            body: JSON.stringify({
                competitionId: competitionId,
                runAllWeeks: runAllWeeks,
                currentWeek: weekNumber
            })
        }).then((result) => {
            console.log("Standings Result:", result.data)
            if (result.data.success) {
                setStandingsData(() => result.data.data)
                setCurrentWeek(() => result.data.data.currentWeek)
            }
            else {
                setStandingsData({})
            }
        })
    }

    const analyzeStandingsData = () => {
        if (standingsData && Object.keys(standingsData).length > 0) {
            const standingsDataList = []
            for (const key in standingsData.summary) {
                let picksetName = null
                if (standingsData.summary[key].isRandomUser) {
                    picksetName = (<Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: key, picksUserId: key, isRandomUser: true})}>
                        {standingsData.summary[key].picksetName}
                    </Link>)
                }
                else {
                    picksetName = (
                        <Link to={{pathname: `/pickem-picks/${competitionId}`}} onClick={() => updateCompetitionData({name: "Need Name Info", picksUserId: key, isRandomUser: false})}>
                            {standingsData.summary[key].picksetName}
                        </Link>
                    )
                }
                let name = ""
                if (standingsData.summary[key].isRandomUser) {
                    name = standingsData.summary[key].randomName
                }
                else {
                    if (standingsData.summary[key].firstName && standingsData.summary[key].lastName) {
                        name = `${standingsData.summary[key].firstName} ${standingsData.summary[key].lastName}`
                    }
                    else if (standingsData.summary[key].firstName) {
                        name = `${standingsData.summary[key].firstName}`
                    }
                    else if (standingsData.summary[key].lastName) {
                        name = `${standingsData.summary[key].lastName}`
                    }
                    else if (standingsData.summary[key].email) {
                        name = `${standingsData[key].email}`
                    }
                }
                const competitorObj = {
                    ...standingsData.summary[key],
                    picksetName: picksetName,
                    name: name,
                    uid: key
                }
                standingsDataList.push(competitorObj)
            }
            standingsDataList.sort((a, b) => b.wins - a.wins)
            setStandingsDisplayData(() => standingsDataList.map((item, i) => ({...item, rank: i + 1})))
        }
        else {
            setStandingsDisplayData([])
        }
    }

    useEffect(() => {
        let name = ""
        if (accountData.firstName && accountData.lastName) {
            name = `${accountData.firstName} ${accountData.lastName}`
        }
        else if (accountData.firstName) {
            name = `${accountData.firstName}`
        }
        else if (accountData.lastName) {
            name = `${accountData.lastName}`
        }
        else if (user.email) {
            name = `${user.email}`
        }
        setNameString(name)
        if (competitionData.competitionDescription) {
            setCompetitionDescription(competitionData.competitionDescription)
        }
        if (competitionData.competitionName) {
            setCompetitionName(competitionData.competitionName)
        }
        setIsCommissioner(competitionData.isCommissioner === user.uid)
    }, [competitionData])

    const weekClicked = (weekData) => {
        if (!weekData.isPost) {
            getStandings(false, weekData.e.target.value)
            setSelectedWeek(weekData.e.target.value)
        }
        
    }

    const createWeeks = (numWeeks, isPost) => {
        const weekList = []
        const selected = {}
        for (let weekNumber = 1; weekNumber <= numWeeks; weekNumber++) {
            const isCurrentWeek = !isPost && currentWeek === weekNumber
            selected[weekNumber] = isCurrentWeek
            weekList.push((<button key={weekNumber} value={weekNumber} onClick={e => weekClicked({e, isPost}, "value")} className={`PickemStandingsPage-weekButton ${isCurrentWeek ? "PickemStandingsPage-currentWeek": "PickemStandingsPage-notCurrentWeek"} ${Number(selectedWeek) === weekNumber ? "PickemStandingsPage-selectedWeek": null}`}>{weekNumber}</button>))
        }
        return weekList
    }

    const RerunStandingsButton = () => {
        return (
            <button style={{ fontSize: "1em", margin: "0.5em", width: "5em" }}
            className={`btn-primary`} onClick={handleUpdateStandings}
        >
            Calculate
        </button>
        )
    }

    const handleUpdateStandings = () => {
        setIsLoading(true)
        functions.httpsCallable('pickemGetStandingsPage')({
            body: JSON.stringify({
                competitionId: competitionId,
                runAllWeeks: true,
                currentWeek: null,
                onlyUpdateStandings: true
            })
        }).then(() => {
            setIsLoading(false)
        })
    }

    return (
        <PickemLayoutPage
            title="Standings"
            name={nameString} competitionName={competitionName} competitionDescription={competitionDescription}
            subtitle={
                <>
                    {
                        isCommissioner ? 
                        <div style={{ display: "flex", fontSize: "1em", justifyContent: "flex-end"}}> 
                                <RerunStandingsButton />                    
                        </div> : null
                    }
                </>
            }
        >
            <div className="PickemStandingsPage">
            <div className="PickemStandingsPage-weekView">
                {!isLoading && standingsData.regWeeks ? <div className="PickemStandingsPage-weeks">
                        <div><p>Regular</p></div>
                        {
                            createWeeks(standingsData.regWeeks, false)
                        }
                </div> : null}
                {!isLoading && standingsData.postWeeks ? <div className="PickemStandingsPage-weeks">
                
                        <div style={{padding: '0.1em'}}><p>Post</p></div>
                        {
                            createWeeks(standingsData.postWeeks, true)
                        }
                </div> : null}
                
            </div>
                {!isLoading && standingsDisplayData ? <PickemTable
                    subject="standings"
                    headList={headList}
                    list={standingsDisplayData}
                    selected={[]}></PickemTable> : <Spinner></Spinner>}
            </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemStandingsPage);
