import React, { useContext, useState, useEffect, useRef } from "react";
import { AuthContext } from "store/AuthProvider";
import { StoreContext } from "store/StoreProvider";
import { PickemLayoutPage } from "layouts/PickemLayoutPage";
import { PickemPicksTable } from "components/common/Table/PickemPicksTable";
import { headListFootball, adminHeadListFootball } from "./model";
//import { Breadcrumbs } from "components/common/Breadcrumbs";
import { NoResults } from "components/common/NoResults";
import { Spinner } from "components/common/Spinner/Spinner";
import { withDialog } from "components/common/withDialog/withDialog";
import { PickemTextInput } from "components/common/Form/TextInput/TextInput";
import PickemCheckbox from "components/common/Form/Checkbox/PickemCheckbox";
import { Select } from "components/common/Form/Select/Select";
//import { Select } from "components/common/Form/Select/Select";
//import Confirmation from "modals/Confirmation";
import { simpleOptions, options } from "utils/helpers";
import "./PickemPicksPage.scss";
//const CryptoJS = require("crypto-js");

//GetPickset -> know competitor, competition, currentWeek, regular weeks + postseason weeks to build the page

const Tiebreakers = ({ tiebreakers, handleTiebreakerInput, tiebreakData }) => {
    return tiebreakData ? (
        <div className="PickemPicksPage-tiebreakers">
        <h2>Tiebreakers</h2>
        <table>
            <tbody>
                {tiebreakData.map((item, i) => {
                    return (<tr className="PickemPicksPage-tiebreakerRow">
                    <td className="PickemPicksPage-tiebreakerTdRight"> 
                        {item.awayTeam}
                    </td>
                    <td className="PickemPicksPage-tiebreakerInput">
                        <input
                            type="text"
                            title= {`Tiebreaker ${i} Away Score`}
                            value= {tiebreakers[`away${i}`] || ""}
                            code={`tiebreak${i}away`}
                            name={`away${i}`}
                            onChange={handleTiebreakerInput}
                            className="PickemPicksPage-tiebreakerInput"
                        />
                    </td>
                    <td className="PickemPicksPage-tiebreakerTd">
                        @
                    </td>
                    <td className="PickemPicksPage-tiebreakerInput">
                    <input
                            type="text"
                            title= {`Tiebreaker ${i} Home Score`}
                            value= {tiebreakers[`home${i}`] || ""}
                            code={`tiebreak${i}home`}
                            name={`home${i}`}
                            onChange={handleTiebreakerInput}
                            className="PickemPicksPage-tiebreakerInput"
                        />
                    </td >
                    
                    <td className="PickemPicksPage-tiebreakerTdLeft">
                        {item.homeTeam}
                    </td>

                </tr>)

                })}
            </tbody>
        </table>
        </div>
    ) : null
}

const PickemPicksPage = ({
    history,
    match: {
        params: {
            competitionId
        }

    }
    }) => {
    const { functions, accountData, user } = useContext(AuthContext);
    const { updateMeta, competitionData, updateCompetitionData } = useContext(StoreContext);
    const [userPickset, setUserPickset] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isPickLoading, setIsPickLoading] = useState(true)
    const [currentWeek, setCurrentWeek] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [selection, setSelection] = useState({})
    const [isSelectedLoading, setIsSelectedLoading] = useState(true)
    const [initialEventData, setInitialEventData] = useState({})
    const [nameString, setNameString] = useState(null)
    const [competitionName, setCompetitionName] = useState(null)
    const [competitionDescription, setCompetitionDescription] = useState(null)
    const [errorLoadingPicks, setErrorLoadingPicks] = useState(false)
    const [isSavingPicks, setIsSavingPicks] = useState(false)
    const [initialSelection, setInitialSelection] = useState({})
    const [isSaveEnabled, setIsSaveEnabled] = useState(false)
    const [tiebreakers, setTiebreakers] = useState({})
    const [tiebreakData, setTiebreakData] = useState(null)
    const [initialTiebreakData, setInitialTiebreakData] = useState(null)
    const [tiebreakDataLoading, setTieBreakDataLoading] = useState(true)
    const [initialTiebreakers, setInitialTiebreakers] = useState({})
    const [isCommissioner, setIsCommissioner] = useState(false)
    const [hiddenEvents, setHiddenEvents] = useState({})
    const [hiddenEventsLoading, setHiddenEventsLoading] = useState(true)
    const [initialHiddenEvents, setInitialHiddenEvents] = useState({})
    const [editEvents, setEditEvents] = useState({})
    const [closingLines, setClosingLines] = useState({})
    const [initialClosingLines, setInitialClosingLines] = useState({})
    const [statusString, setStatusString] = useState(null)
    let editableKeyToFocus = useRef(null);

    useEffect(() => {
        updateMeta({
            title: "Picks",
        });
        return clearUser
    }, []);

    useEffect(() => {
        let name = ""
        if (competitionData.name) {
            name = competitionData.name
        }
        else {
            if (accountData.firstName && accountData.lastName) {
                name = `${accountData.firstName} ${accountData.lastName}`
            }
            else if (accountData.firstName) {
                name = `${accountData.firstName}`
            }
            else if (accountData.lastName) {
                name = `${accountData.lastName}`
            }
            else if (user.email) {
                name = `${user.email}`
            }
        }
        setNameString(name)
        if (competitionData.competitionDescription) {
            setCompetitionDescription(competitionData.competitionDescription)
        }
        if (competitionData.competitionName) {
            setCompetitionName(competitionData.competitionName)
        }
        setIsCommissioner(competitionData.isCommissioner === user.uid)
    }, [competitionData])

    useEffect(() => {
        getPickset(currentWeek)
    }, [])

    useEffect(() => {
        setIsPickLoading(false)
    }, [userPickset])

    useEffect(() => {
        setTieBreakDataLoading(false)
    }, [tiebreakData])

    useEffect(() => {
        setHiddenEventsLoading(false)
    }, [hiddenEvents])

    useEffect(() => {
        setIsSelectedLoading(false)
        analyzeUserPickset()
    }, [selection, initialEventData, initialHiddenEvents, tiebreakers, hiddenEvents, closingLines, editEvents, isSaveEnabled, isCommissioner, tiebreakData])

    const clearUser = () => {
        updateCompetitionData({picksUserId: user.uid, isRandomUser: false, name: null})
    }

    const getPickset = (weekNumber) => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid
        functions.httpsCallable('pickemGetPickset')({
            body: JSON.stringify({
                competitionId: competitionId,
                userId: userId,
                isRandomUser: competitionData.isRandomUser,
                currentWeek: weekNumber
            })
        }).then((result) => {
            setIsLoading(false)
            if (result.data.success) {
                setIsLoading(false)
                setIsSelectedLoading(true)
                addUserPicks({...result.data.data.eventData})
                setTiebreakData(() => ([...result.data.data.tiebreakers]))
                setInitialTiebreakData(() => ([...result.data.data.tiebreakers]))
                setInitialEventData(() => ({...result.data.data.eventData}))
                setInitialTiebreakers(() => ({...result.data.data.userTiebreakerPicks}))
                setInitialHiddenEvents(() => ({...result.data.data.hiddenEvents}))
                setHiddenEvents(() => ({...result.data.data.hiddenEvents}))
                setInitialClosingLines(() => ({...result.data.data.closingLines}))
                setClosingLines(() => ({...result.data.data.closingLines}))
                setTiebreakers(() => ({...result.data.data.userTiebreakerPicks}))
                if (!weekNumber) {
                    setCurrentWeek(result.data.data.currentWeek) 
                }
            } else {
                setIsLoading(false)
                setIsSelectedLoading(false)
                setErrorLoadingPicks(true)
            }
        })
    }

    const addUserPicks = (userData) => {
        if (userData.currentWeek) {
            const selectedInfo = {}
            for (const pick of userData.currentWeek) {
                selectedInfo[pick.eventId] = pick.userPick
            }
            setSelection(() => ({...selectedInfo}))
            setInitialSelection(() => ({...selectedInfo}))
        }
        
    }

    const handleRadioChange = (
        value, eventId
    ) => {
        if (Object.keys(selection).length > 0) {
            if (isCommissioner || competitionData.picksUserId === user.uid) {
                setSelection((prevSelect) => ({
                    ...prevSelect,
                    [eventId]: value
                }))
            }
        }
    };

    const handleHideEventCheck = (eventId) => {
        let hiddenEventsNew = {...hiddenEvents}
        if (hiddenEventsNew[eventId]) {
            hiddenEventsNew[eventId] = !hiddenEventsNew[eventId]
        }
        else {
            hiddenEventsNew[eventId] = true
        }

        setHiddenEvents({...hiddenEventsNew })
    }

    const handleEditEventCheck = (eventId) => {
        let editEventsNew = {...editEvents}
        if (editEventsNew[eventId]) {
            editEventsNew[eventId] = !editEventsNew[eventId]
        }
        else {
            editEventsNew[eventId] = true
        }

        setEditEvents({...editEventsNew })
    }

    const handleTiebreakerDataChecked = (eventId) => {
        if (eventId !== tiebreakData[0].eventId) {
            const newTiebreakData = []
            const eventInfo = initialEventData.currentWeek.filter((item) => item.eventId === eventId)
            newTiebreakData.push(eventInfo[0])
            newTiebreakData.push(tiebreakData[0])
            setTiebreakData(newTiebreakData)
        }
    }

    const setClosingLine = (lineData) => {
        editableKeyToFocus.current = lineData.target.name
        if (lineData.target.value === "") {
            let closingLinesNew = {...closingLines}
            closingLinesNew[lineData.target.name] = ""
            setClosingLines({...closingLinesNew })
        }
        else {
            let closingLinesNew = {...closingLines}
            let rx = new RegExp(/^\d*\.?\d*$/);
            if (rx.test(lineData.target.value)) {
                
                closingLinesNew[lineData.target.name] = lineData.target.value
                setClosingLines({...closingLinesNew })
            }
            const saveEnabled = closingLinesNew[lineData.target.name] !== initialClosingLines[lineData.target.name]
            setIsSaveEnabled(true)
        }
        
    }

    const analyzeUserPickset = () => {
        if (initialEventData.currentWeek) {
            let saveEnabled = false
            const userPicksetData = {
                currentWeek: [],
                regWeeks: initialEventData.regWeeks,
                postWeeks: initialEventData.postWeeks
            }
            let totalPoints = 0
            let totalPicks = 0
            let possiblePicks = 0
            //console.log("Analyze Picks Current Data:", currentData)
            for (const event of initialEventData.currentWeek) {
                
                if (!isCommissioner && hiddenEvents[event.eventId]) {
                    continue;
                }
                if (!hiddenEvents[event.eventId]) {
                    possiblePicks += 1
                    totalPicks += initialSelection[event.eventId] ? 1 : 0
                }

                if (!saveEnabled)
                    saveEnabled = selection[event.eventId] !== initialSelection[event.eventId]

                const favoritePicked = (<div><input
                    type="radio"
                    id="favorite"
                    key={`${event.eventId}-favorite`}
                    value={event.favorite}
                    checked={
                        selection[event.eventId] === event.favorite
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.favorite, event.eventId
                        )
                    }
                /></div>)
                const vs = "vs"
                const underdogPicked = (<div><input
                    type="radio"
                    id="underdog"
                    key={`${event.eventId}-underdog`}
                    value={event.underdog}
                    checked={
                        selection[event.eventId] === event.underdog
                    }
                    onChange={() =>
                        handleRadioChange(
                            event.underdog, event.eventId
                        )
                    }
                /></div>)
                let pointSpreadFavorite = parseFloat(closingLines[event.eventId])|| Math.abs(parseFloat(event.pointSpreadFavorite))
                pointSpreadFavorite = pointSpreadFavorite.toLocaleString("en", { minimumFractionDigits: 1 })

                if (!saveEnabled) {
                    if (initialClosingLines[event.eventId] && closingLines[event.eventId]) {
                        saveEnabled = initialClosingLines[event.eventId] !== closingLines[event.eventId]
                    }
                    else if (closingLines[event.eventId]) {
                        saveEnabled = parseFloat(closingLines[event.eventId]).toLocaleString("en", { minimumFractionDigits: 1 }) !== Math.abs(parseFloat(event.pointSpreadFavorite)).toLocaleString("en", { minimumFractionDigits: 1 })
                    }
                }

                let userPickObj = {
                    ...event,
                    underdogPicked: underdogPicked,
                    vs: vs,
                    favoritePicked: favoritePicked,
                    id: event.eventId,
                    spread: pointSpreadFavorite
                }

                if (isCommissioner) {
                    const hideEventButton = (
                        <div className="PickemPicksPage-hideEventDiv">
                            <PickemCheckbox
                            inputData={{
                                id: event.eventId,
                                checked: hiddenEvents[event.eventId],
                                value: event.eventId,
                                }}
                                onChange={handleHideEventCheck}
                            />
                        </div>
                    )

                    const editEventButton = (
                        <div className="PickemPicksPage-hideEventDiv">
                            <PickemCheckbox
                            inputData={{
                                id: `${event.eventId}-edit`,
                                checked: editEvents[event.eventId],
                                value: event.eventId,
                                }}
                                onChange={handleEditEventCheck}
                            />
                        </div>
                    )
                    //const tiebreakerList = tiebreakData.map((item) => item.eventId)
                    const isTiebreaker = tiebreakData.filter((e) => e.eventId === event.eventId)     
                    console.log("Tiebreaker:", isTiebreaker, event.eventId)    
                    const editTiebreakersButton = (
                        <div className="PickemPicksPage-hideEventDiv">
                            <PickemCheckbox
                            inputData={{
                                id: `${event.eventId}-tiebreak`,
                                checked: isTiebreaker.length > 0 ? true : false,
                                value: event.eventId,
                                }}
                                onChange={handleTiebreakerDataChecked}
                            />
                        </div>
                    )

                    const spread = (
                        <div className="PickemPicksPage-hideEventDiv">
                            <PickemTextInput
                                inputData={{
                                    placeholder: "",
                                    value: closingLines[event.eventId] || closingLines[event.eventId] === "" ? closingLines[event.eventId] : pointSpreadFavorite,
                                    type: "text",
                                    name: event.eventId,
                                    autoFocus: event.eventId === editableKeyToFocus.current,
                                    onFocus: (e)=>(e.selectionStart = e.target.value.length),
                                    readOnly: editEvents[event.eventId] ? null : "readOnly",
                                    style: {textAlign: 'right', width: '5em'}
                                }}
                                code="name"
                                onChange={setClosingLine}
                            />
                        </div>
                    )

                    userPickObj = {
                        ...userPickObj,
                        hide: hideEventButton,
                        edit: editEventButton,
                        spread: editEvents[event.eventId] ? spread : pointSpreadFavorite,
                        tiebreaker: editTiebreakersButton
                    }
                }
                totalPoints += event.point
                userPicksetData.currentWeek.push(userPickObj)
            }
            const totalObj = {
                pickOutcome: " ",
                favorite: " ",
                favoritePicked: " ",
                vs: " ",
                underdogPicked: " ",
                underdog: " ",
                open: " ",
                monday: " ",
                tuesday: " ",
                wednesday: " ",
                spread: " ",
                startAt: "TOTAL POINTS",
                point: totalPoints
            }

            userPicksetData.currentWeek.push(totalObj)
            for (const key of Object.keys(tiebreakers)) {
                if (!saveEnabled)
                    saveEnabled = initialTiebreakers[key] !== tiebreakers[key]
            }
            let tiebreakersPossible = 0
            let tiebreakersPicked = 0
            for (let i = 0; i < tiebreakData.length; i++) {
                tiebreakersPossible += 1
                tiebreakersPicked += tiebreakers[`home${i}`] && tiebreakers[`away${i}`] ? 1 : 0
            }

            for (const key of Object.keys(hiddenEvents)) {
                if (Object.keys(initialHiddenEvents) > 0) {
                    if (!saveEnabled)
                        saveEnabled = initialHiddenEvents[key] !== hiddenEvents[key]
                }
                else {
                    if (!saveEnabled)
                        saveEnabled = hiddenEvents[key]
                }
            }

            for (let i = 0; i < tiebreakData.length; i++) {
                if (!saveEnabled) {
                    saveEnabled = initialTiebreakData[i].eventId !== tiebreakData[i].eventId
                }
            }
            const status = `Picks Saved: ${totalPicks} of ${possiblePicks}, Tiebreakers Saved: ${tiebreakersPicked} of ${tiebreakersPossible}`
            setStatusString(status)
            setIsSaveEnabled(saveEnabled)
            setUserPickset(userPicksetData)
        }
        
    }

    const weekClicked = (weekData) => {
        if (!weekData.isPost) {
            getPickset(weekData.e.target.value)
            setSelectedWeek(weekData.e.target.value)
            setIsPickLoading(true)
        }
        
    }

    const createWeeks = (numWeeks, isPost) => {
        const weekList = []
        const selected = {}
        for (let weekNumber = 1; weekNumber <= numWeeks; weekNumber++) {
            const isCurrentWeek = !isPost && currentWeek === weekNumber
            selected[weekNumber] = isCurrentWeek
            //weekList.push((<div key={weekNumber} onClick={weekClicked} className={`col PickemPicksPage-weeks ${isCurrentWeek ? "PickemPicksPage-selected": null}`}><p>{weekNumber}</p></div>))
            weekList.push((<button key={weekNumber} value={weekNumber} onClick={e => weekClicked({e, isPost}, "value")} className={`PickemPicksPage-weekButton ${isCurrentWeek ? "PickemPicksPage-currentWeek": "PickemPicksPage-notCurrentWeek"} ${Number(selectedWeek) === weekNumber ? "PickemPicksPage-selectedWeek": null}`}>{weekNumber}</button>))
        }
        return weekList
    }

    const handleSavePicks = () => {
        const userId = competitionData.picksUserId ? competitionData.picksUserId : user.uid
        let updateAllStandings = false
        for (const key of Object.keys(closingLines)) {
            if (closingLines[key] === "") {
                closingLines[key] = "0.0"
            }
            else {
                closingLines[key] = parseFloat(closingLines[key]).toLocaleString("en", { minimumFractionDigits: 1 })
            }
        }
        const hiddenList = Object.keys(hiddenEvents).filter((key) => hiddenEvents[key])
        const newTiebreakList = []
        for (let i = 0; i < tiebreakData.length; i++) {
            if (tiebreakData[i].eventId !== initialTiebreakData[i].eventId) {
                newTiebreakList.push(tiebreakData[i].eventId)
            }
        }
        for (const key of Object.keys(closingLines)) {
            if (closingLines[key] !== initialClosingLines[key]) {
                updateAllStandings = true
            }
        }
        for (const key of Object.keys(hiddenEvents)) {
            if (hiddenEvents[key] !== initialHiddenEvents[key]) {
                updateAllStandings = true
            }
        }
        if (newTiebreakList.length > 0) {
            updateAllStandings = true
        }
        const savePicksetObj = {
            competitionId: competitionId,
            currentWeek: selectedWeek || currentWeek,
            pickData: selection,
            tiebreakers: tiebreakers,
            isRandomUser: competitionData.isRandomUser,
            hiddenEvents: hiddenList,
            closingLines: closingLines,
            tiebreakData: newTiebreakList,
            userId: userId,
            isCommissioner: isCommissioner
        }

        setIsSavingPicks(true)
        functions.httpsCallable('pickemSavePickset')({
            body: JSON.stringify({...savePicksetObj})
        }).then((result) => {
            setIsSavingPicks(false)
            setIsSaveEnabled(false)
            if (result.data.success) {
                if (updateAllStandings) {
                    functions.httpsCallable('pickemGetStandingsPage')({
                        body: JSON.stringify({
                            competitionId: competitionId,
                            runAllWeeks: true,
                            currentWeek: null,
                            onlyUpdateStandings: true
                        })
                    })
                    setInitialSelection(selection)
                    setInitialTiebreakers(tiebreakers)
                    setInitialHiddenEvents(hiddenList)
                    setInitialTiebreakData(tiebreakData)
                } else {
                    setInitialSelection(selection)
                    setInitialTiebreakers(tiebreakers)
                    setInitialHiddenEvents(hiddenList)
                    setInitialTiebreakData(tiebreakData)
                }
                
            }
        }) 

    }

    const handleTiebreakerInput = (e) => {
        const tiebreakerUpdate = {}
        tiebreakerUpdate[`${e.target.name}`] = e.target.value
        setTiebreakers(current => ({
            ...current,
            ...tiebreakerUpdate
        }))

    }

    const handleCancelPicks = () => {
        addUserPicks({...initialEventData})
        setTiebreakers({...initialTiebreakers})
        setHiddenEvents({...initialHiddenEvents})
        setTiebreakData([...initialTiebreakData])
    }



    const SaveButton = () => {
        return (
            <button style={{ fontSize: "1em", margin: "0.5em", width: "5em" }}
            className={`btn-primary ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleSavePicks} disabled={!isSaveEnabled}
        >
            Save
        </button>
        )
    }

    const CancelButton = () => {
       return ( <button style={{ fontSize: "1em", margin: "0.5em", width: "5em" }}
            className={`btn-secondary ${!isSaveEnabled ? "PickemPicksPage-disabled" : null}`} onClick={handleCancelPicks} disabled={!isSaveEnabled}
        >
            Cancel
        </button>
       )
    }

    const StatusBar = () => {
        return (!isLoading && !isSavingPicks && statusString ? <div className="PickemPicksPage-statusBar"><p>{statusString}</p></div> : null)
    }

    return (
        <PickemLayoutPage
            title={`${competitionData.userPicksetName} Picks`}
            name={nameString} 
            competitionName={competitionName} 
            competitionDescription={competitionDescription}
            isDifferentUser={competitionData.picksUserId ? competitionData.picksUserId !== user.uid : false}
            subtitle={
                <>
                    {
                        <div style={{ display: "flex", fontSize: "1em", justifyContent: "flex-end"}}>
                            
                                <SaveButton />
                                <CancelButton />
                            
                        </div>
                    }
                </>
            }
        >
            <div className="PickemPicksPage">
            <div className="PickemPicksPage-weekView">
                {!isLoading && userPickset.regWeeks ? <div className="PickemPicksPage-weeks">
                        <div><p>Regular</p></div>
                        {
                            createWeeks(userPickset.regWeeks, false)
                        }
                </div> : null}
                {!isLoading && userPickset.postWeeks ? <div className="PickemPicksPage-weeks">
                
                        <div style={{padding: '0.1em'}}><p>Post</p></div>
                        {
                            createWeeks(userPickset.postWeeks, true)
                        }
                </div> : null}
                
            </div>
            <div className="PickemPicksPage-weekView">
                <StatusBar/> 
            </div>

            
            {!isPickLoading && userPickset.currentWeek && !tiebreakDataLoading && !isSelectedLoading && !isSavingPicks && !hiddenEventsLoading ? 
                <div>
                    <PickemPicksTable
                    subject="picks"
                    headList={isCommissioner ? adminHeadListFootball : headListFootball}
                    list={userPickset.currentWeek}
                    selected={[]}
                    /> 
                    <Tiebreakers 
                        tiebreakData={tiebreakData}
                        tiebreakers={tiebreakers}
                        handleTiebreakerInput={handleTiebreakerInput}/>
                    
                    <div style={{ display: "flex", fontSize: "1em", margin: 0.5 }} className="PickemPicksPage-saveButtons">
                    <div className="PickemPicksPage-weekView">
                        <StatusBar/> 
                    </div>
                        <SaveButton />
                        <CancelButton />
                    </div>
                </div>
                : !errorLoadingPicks ? <Spinner></Spinner> : <NoResults
                label={"Error retrieving pickset"}
            />}
                </div>
            
        </PickemLayoutPage>
    );
};

export default withDialog(PickemPicksPage);
